
  import { defineComponent, ref, onMounted, watch } from "vue";
  import IpWorkPlanModal from "@/components/modals/forms/ip_work_plan/IpWorkPlanModal.vue";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import ApiService from "@/core/services/ApiService";
  import { apiEndpoint } from "@/mixin/apiMixin";
  import { ElNotification } from "element-plus";
  import { useBus } from "@/bus.ts";
  import { DrawerComponent } from "@/assets/ts/components";
  import { months } from "@/core/helpers/genericHelper.ts";
  import { VueCookieNext } from "vue-cookie-next";
  import {useRouter} from "vue-router";
  
  export default defineComponent({
    name: "activityList",
    components: {
      IpWorkPlanModal,
    },
    setup() {
      const router = useRouter();
      const { bus } = useBus();
      const editData = ref(null);
      const activityList = ref([]);
      const iaPartnerOptions = ref([] as { value: any, text: string }[]);
      const selectedStatus = ref("");
      const selectedPartner = ref("");
      const selectedYear = ref("");
      const workPlanPermission = ref("");
      const exportType = ref(null);
  
      const create = (data) => {
        editData.value = data ? data : null;
      };
  
      const edit = (data) => {
        editData.value = data;
      };
  
      const show = (item) => {
        // Add logic for showing item
      };
  
      const deleteActivity = async (id) => {
        Swal.fire({
          title: "Are you sure you want to delete it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await ApiService.post(
                apiEndpoint.data().VUE_APP_OUTPUT_DELETE,
                {
                  id: id,
                }
              );
  
              if (response.data.status == "success") {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.data,
                  type: "success",
                });
                loadData();
              } else {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.message,
                  type: "error",
                });
              }
            } catch (error) {
              console.error("Error deleting output:", error);
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: "Error deleting output",
                type: "error",
              });
            }
          }
        });
      };
  
      const getIaPartnerOptions = async () => {
        try {
          const id =
            VueCookieNext.getCookie("_ia_partner_id") !== "null"
              ? VueCookieNext.getCookie("_ia_partner_id")
              : 0;
  
          const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {
              id: id,
            }
          );
  
          iaPartnerOptions.value = response.data.data.map((option) => ({
            value: option.id,
            text: option.name,
          }));
  
        } catch (error) {
          console.error("Error fetching parent output options:", error);
        }
      };
  
      const loadData = async () => {
        try {
          const ia_partner_id =
              selectedPartner.value ||
              (VueCookieNext.getCookie("_ia_partner_id") !== "null" ? VueCookieNext.getCookie("_ia_partner_id") : 0);
  
          const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IPWORKPLAN_LIST,
            {
              ia_partner_id: ia_partner_id,
              status: selectedStatus.value,
              year: selectedYear.value,
            }
          );
  
          activityList.value = response.data.data;
  
  
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      const editEvent = (id) => {
        router.push({ name: 'save-event', params: { id: id } });
      };
  
      const exportChange = async () => {
        if (exportType.value == "excel") {
          exportExcel();
        }
      };
  
      const exportExcel = async () => {
        try {
          const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_QUARTERLY_REPORT_EXPORT,
              {}
          );
  
          if (response.data.status === "success" && response.data.data) {
            // Create a link element
            const downloadLink = document.createElement("a");
            downloadLink.href = apiEndpoint.data().BEE_FILE_URL+response.data.data;
            console.log(downloadLink.href);
            downloadLink.target = "_blank"; // Open in a new tab/window
            downloadLink.download = "quarterly_report.xlsx"; // Specify the desired file name
  
            // Append the link to the document
            document.body.appendChild(downloadLink);
  
            // Trigger a click event on the link to start the download
            downloadLink.click();
  
            // Remove the link from the document
            document.body.removeChild(downloadLink);
          } else {
            console.error("Invalid response for export:", response);
          }
        } catch (error) {
          console.error("Error exporting Excel:", error);
        }
      };
  
      onMounted(async () => {
        DrawerComponent.bootstrap();
        DrawerComponent.updateAll();
  
        const user = VueCookieNext.getCookie("_cpv_user");
  
        workPlanPermission.value = user.ia_partner ? user.ia_partner.workplan_permission : '';
  
        loadData();
        getIaPartnerOptions();
  
        bus.on("loadData", async () => {
          loadData();
        });
      });
  
  
  
      return {
        editData,
        activityList,
        create,
        edit,
        show,
        deleteActivity,
        months,
        iaPartnerOptions,
        selectedStatus,
        selectedPartner,
        selectedYear,
        loadData,
        workPlanPermission,
        editEvent,
        exportChange,
        exportType
      };
    },
  });
  